@charset "UTF-8";
.webks-tools-page-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9998;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.45); }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle:focus {
  outline: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }
  .dropdown-menu.pull-right {
    right: 0;
    left: auto; }
  .dropdown-menu .divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857;
    color: #888888;
    white-space: nowrap; }
    .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
      color: #7b7b7b;
      text-decoration: none;
      background-color: #f5f5f5; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #26509c;
  outline: 0; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #cccccc; }

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false); }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  color: #cccccc;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9; }

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px; }

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto; }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto; } }

.dropdown-pane {
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  display: block;
  font-size: 1rem;
  padding: 15px;
  position: absolute;
  visibility: hidden;
  width: 300px;
  z-index: 10; }
  .dropdown-pane.is-open {
    visibility: visible; }
  .dropdown-pane.tiny {
    width: 100px; }
  .dropdown-pane.small {
    width: 200px; }
  .dropdown-pane.large {
    width: 400px; }

.btn,
.button,
.btn-inside a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  border-color: #26509c;
  background-color: #26509c;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  .btn:hover,
  .button:hover,
  .btn-inside a:hover {
    border-color: #2b5bb1;
    background-color: #2b5bb1;
    color: #fff; }
  .btn:active, .btn:focus,
  .button:active,
  .button:focus,
  .btn-inside a:active,
  .btn-inside a:focus {
    border-color: #214587;
    background-color: #214587;
    color: #fff; }
  .btn:hover,
  .button:hover,
  .btn-inside a:hover {
    text-decoration: none; }

.btn--sucess,
.btn-inside--sucess a {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #fff; }
  .btn--sucess:hover,
  .btn-inside--sucess a:hover {
    background-color: #67bd67;
    border-color: #67bd67;
    color: #fff; }
  .btn--sucess:active, .btn--sucess:focus,
  .btn-inside--sucess a:active,
  .btn-inside--sucess a:focus {
    background-color: #51b351;
    border-color: #51b351;
    color: #357935; }

.btn--danger,
.btn-inside--danger a {
  background-color: #d9534f;
  border-color: #d9534f;
  text-shadow: 0 1px 1px #e48481;
  color: #fff; }
  .btn--danger:hover,
  .btn-inside--danger a:hover {
    background-color: #dc5f5c;
    border-color: #dc5f5c;
    color: #fff !important; }
  .btn--danger:active, .btn--danger:focus,
  .btn-inside--danger a:active,
  .btn-inside--danger a:focus {
    background-color: #d64742;
    border-color: #d64742;
    color: #a02622; }

.btn--ghost,
.btn-inside--ghost a {
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222; }
  .btn--ghost:hover,
  .btn-inside--ghost a:hover {
    background-color: transparent;
    border-color: #bfbfbf;
    color: #26509c; }
  .btn--ghost:active, .btn--ghost:focus,
  .btn-inside--ghost a:active,
  .btn-inside--ghost a:focus {
    background-color: transparent;
    border-color: #9e9e9e;
    color: #222; }

.btn--disabled,
.btn-inside--disabled a,
button:disabled,
input[type="submit"]:disabled {
  background-color: #c4c4c4;
  border-color: #aaaaaa;
  color: #848484; }
  .btn--disabled:hover,
  .btn-inside--disabled a:hover,
  button:disabled:hover,
  input[type="submit"]:disabled:hover {
    background-color: #bfbfbf;
    border-color: #aaaaaa;
    color: #848484; }
  .btn--disabled:active, .btn--disabled:focus,
  .btn-inside--disabled a:active,
  .btn-inside--disabled a:focus,
  button:disabled:active,
  button:disabled:focus,
  input[type="submit"]:disabled:active,
  input[type="submit"]:disabled:focus {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
    color: #999999; }

.btn--naked,
.btn-inside--naked a {
  border-color: transparent;
  background-color: transparent;
  color: #222;
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0; }
  .btn--naked:hover,
  .btn-inside--naked a:hover {
    border-color: transparent;
    background-color: transparent;
    color: #26509c; }
  .btn--naked:active, .btn--naked:focus,
  .btn-inside--naked a:active,
  .btn-inside--naked a:focus {
    border-color: transparent;
    background-color: transparent;
    color: #26509c; }

.btn--xs {
  padding: 8px 10px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px; }

.btn--xl {
  padding: 18.75px 25px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }

.btn--xxl {
  padding: 17.25px 65px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }

.btn--xxxl {
  padding: 33.75px 125px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px; }

.btn--circle {
  text-indent: -9000px;
  text-align: left;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  line-height: 48px;
  padding: 0 !important; }
  .btn--circle::before {
    float: left;
    text-indent: 0;
    font-size: 18px;
    vertical-align: middle;
    width: 48px;
    line-height: 48px !important;
    text-align: center;
    margin: 0 0 0 -1px !important; }
  .btn--circle.btn--xs {
    height: 28px;
    width: 28px;
    line-height: 28px; }
    .btn--circle.btn--xs::before {
      font-size: 12px;
      height: 28px;
      width: 28px;
      line-height: 28px !important; }
  .btn--circle.btn--xl {
    height: 69.5px;
    width: 69.5px;
    line-height: 69.5px; }
    .btn--circle.btn--xl::before {
      font-size: 32px;
      height: 69.5px;
      width: 69.5px;
      line-height: 69.5px !important; }
  .btn--circle.btn--xxl {
    height: 80.5px;
    width: 80.5px;
    line-height: 80.5px; }
    .btn--circle.btn--xxl::before {
      font-size: 46px;
      height: 80.5px;
      width: 80.5px;
      line-height: 80.5px !important; }
  .btn--circle.btn--xxxl {
    height: 123.5px;
    width: 123.5px;
    line-height: 123.5px; }
    .btn--circle.btn--xxxl::before {
      font-size: 56px;
      height: 123.5px;
      width: 123.5px;
      line-height: 123.5px !important; }

.btn--block,
.btn-inside--blocked a {
  display: block; }

.btn::before {
  display: inline-block;
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-right: 5px; }

@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont/iconfont.eot?-cbfawx");
  src: url("../fonts/iconfont/iconfont.eot?#iefix-cbfawx") format("embedded-opentype"), url("../fonts/iconfont/iconfont.woff2?-cbfawx") format("woff2"), url("../fonts/iconfont/iconfont.woff?-cbfawx") format("woff"), url("../fonts/iconfont/iconfont.ttf?-cbfawx") format("truetype"), url("../fonts/iconfont/iconfont.svg?-cbfawx#iconfont") format("svg");
  font-weight: normal;
  font-style: normal; }

.ico::before,
.ico-inline::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px; }

.ico-seperate {
  text-indent: -9000px;
  text-align: left;
  display: inline-block;
  float: left;
  margin-right: 4px;
  font-size: 0;
  width: 18px; }
  .ico-seperate::before {
    text-indent: 0;
    font-family: "iconfont";
    float: right;
    text-align: center;
    width: 100%;
    font-size: 18px; }
  .ico-seperate::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }

.menu > li > a.ico-inline::before {
  font-size: 16px;
  font-weight: normal !important;
  vertical-align: middle; }

.ico--search:before {
  content: ""; }

.ico--check:before {
  content: ""; }

.ico--circle-check:before {
  content: ""; }

.ico--remove:before {
  content: ""; }

.ico--circle-remove:before {
  content: ""; }

.ico--plus:before {
  content: "+"; }

.ico--circle-plus:before {
  content: "*"; }

.ico--minus:before {
  content: "-"; }

.ico--circle-minus:before {
  content: "_"; }

.ico--exclamation:before {
  content: ""; }

.ico--circle-exclamation:before {
  content: ""; }

.ico--info:before {
  content: ""; }

.ico--circle-info:before {
  content: ""; }

.ico--menu:before {
  content: ""; }

.ico--print:before {
  content: ""; }

.ico--phone:before {
  content: ""; }

.ico--mail:before {
  content: ""; }

.ico--form:before {
  content: ""; }

.ico--user:before {
  content: ""; }

.ico--user-group:before {
  content: ""; }

.ico--user-add:before {
  content: ""; }

.ico--cart:before {
  content: ""; }

.ico--arrow-top:before {
  content: ""; }

.ico--arrow-right:before {
  content: ""; }

.ico--arrow-bottom:before {
  content: ""; }

.ico--arrow-left:before {
  content: ""; }

.ico--arrow-thin-top:before {
  content: ""; }

.ico--arrow-thin-right:before {
  content: ""; }

.ico--arrow-thin-bottom:before {
  content: ""; }

.ico--arrow-thin-left:before {
  content: ""; }

.ico--circle-arrow-top:before {
  content: ""; }

.ico--circle-arrow-right:before {
  content: ""; }

.ico--circle-arrow-bottom:before {
  content: ""; }

.ico--circle-arrow-left:before {
  content: ""; }

.ico--double-arrow-top:before {
  content: "Y"; }

.ico--double-arrow-down:before {
  content: "y"; }

.ico--locked:before {
  content: ""; }

.ico--home:before {
  content: ""; }

.ico--twitter:before {
  content: ""; }

.ico--facebook:before {
  content: ""; }

.ico--gplus:before {
  content: ""; }

.ico--youtube:before {
  content: ""; }

.ico--facebook-frame:before {
  content: ""; }

.ico--rss-frame:before {
  content: ""; }

.ico--xs::before {
  font-size: 12px; }

.ico--xs.ico-seperate {
  width: 12px;
  height: 12px;
  line-height: 12px; }

.ico--normal::before {
  font-size: 18px; }

.ico--normal.ico-seperate {
  width: 18px;
  height: 18px;
  line-height: 18px; }

.ico--xl::before {
  font-size: 32px; }

.ico--xl.ico-seperate {
  width: 32px;
  height: 32px;
  line-height: 32px; }

.ico--xxl::before {
  font-size: 46px; }

.ico--xxl.ico-seperate {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.ico--xxxl::before {
  font-size: 56px; }

.ico--xxxl.ico-seperate {
  width: 56px;
  height: 56px;
  line-height: 56px; }

.icon-combo {
  text-align: center; }
  .icon-combo__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; }
  .icon-combo__icon {
    display: inline-block;
    font-size: 56px; }

.ico--facebook-frame {
  color: #3b5998; }

.ico--rss-frame {
  color: #ff9f2d; }

.box-style {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff; }
  .box-style:before, .box-style:after {
    display: table;
    content: " "; }
  .box-style:after {
    clear: both; }

.box-style--info {
  position: relative;
  font-size: 12px;
  padding-left: 60px;
  line-height: 1.1em;
  border-color: #39b3d7;
  text-shadow: 1px 1px 1px #39b3d7;
  background-color: #5bc0de;
  color: #fff;
  cursor: help; }
  .box-style--info::before {
    font-family: "iconfont";
    content: ""; }
  .box-style--info::before {
    text-shadow: none;
    font-family: "iconfont";
    color: #39b3d7;
    background-color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    position: absolute;
    left: 15px;
    top: 13px;
    font-size: 22px; }

.webks-expandable-container {
  position: relative; }
  .webks-expandable-container.default-hidden:not(.webks-expandable-container--expanded) .block-content,
  .webks-expandable-container.default-hidden:not(.webks-expandable-container--expanded) .webks-expandable-container__content {
    display: none; }
  .webks-expandable-container--expanded .webks-expandable-container__content {
    display: block; }
  .webks-expandable-container__trigger {
    cursor: pointer; }
  .webks-expandable-container__content:hover {
    z-index: 1000 !important; }

html:not(.js) .block--expandable {
  display: none; }

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 22px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out; }
  .thumbnail > img,
  .thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto; }
  .thumbnail .caption {
    padding: 15px;
    color: #222; }

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: #222; }

.tile {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.36);
  position: relative; }
  .tile__contents {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 15px; }
  .tile__image {
    line-height: 0; }
  .tile__overlay-link {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: block;
    text-indent: -9000px; }
  .tile__title {
    color: #fff;
    margin: 0;
    font-size: 22px;
    line-height: 1.5; }
  .tile__price {
    font-size: 22px;
    line-height: 1.5;
    white-space: nowrap; }

.image-wrapper {
  position: relative;
  text-align: center;
  min-height: 100px; }
  .image-wrapper > .field {
    display: block;
    margin-bottom: 0;
    height: 100%;
    overflow: hidden; }
    .image-wrapper > .field > .field-items,
    .image-wrapper > .field > .field-items > .field-item,
    .image-wrapper > .field > .field-items > .field-item > a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
    .image-wrapper > .field img {
      display: inline;
      vertical-align: middle; }

.thumbnail .image-wrapper {
  padding-top: 15px; }

.thumbnail .read-on {
  padding-top: 15px; }

.thumbnail .field-name-field-produkt-tags {
  margin-top: 0.75rem; }

.thumbnail .caption h2 {
  margin-top: 0; }

.owl-item .thumbnail {
  border: 0 none !important;
  padding: 0 !important; }

.owl-item .image-wrapper {
  margin-top: 15px; }
  .owl-item .image-wrapper .percent-badge {
    right: 0; }

.owlcarousel-settings-einzellogos .owl-stage {
  display: flex;
  align-items: center; }

.owlcarousel-settings-einzellogos .owl-item {
  padding-left: 5px;
  padding-right: 5px; }

.card {
  position: relative;
  background: #fff;
  z-index: 4;
  margin: 0 !important;
  transition-property: border-width, border-color, padding, margin;
  transition-duration: 250ms; }
  .card__image {
    line-height: 0;
    margin-bottom: 1em; }
    .card__image .field {
      margin: 0 !important; }
  .card__title {
    font-size: 22px;
    line-height: 1.1; }
    .card__title .first-word {
      display: block; }
  .card__subline {
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.1em; }
    .card__subline .field {
      margin: 0;
      font-weight: 300;
      font-size: 12px; }
  .card__overlay-link {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: block;
    text-indent: -9000px; }

@media (min-width: 768px) {
  .media-object {
    display: table;
    width: 100%;
    display: flex; } }

.media-object__image {
  text-align: center;
  line-height: 0;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .media-object__image {
      max-width: 35%;
      width: 35%;
      margin-bottom: 0;
      padding-right: 30px;
      display: table-cell;
      vertical-align: top; } }
  @media (min-width: 992px) {
    .media-object__image {
      width: 175px;
      min-width: 175px; } }
  .media-object__image .field {
    margin: 0 !important;
    float: none !important; }
  .media-object__image a {
    display: block;
    background-color: #cccccc; }

.media-object__content {
  display: table-cell;
  vertical-align: top;
  display: flex;
  flex: 1;
  flex-direction: column; }

.media-object__title {
  margin-top: 0;
  margin-bottom: 7.5px;
  color: #26509c; }
  .media-object__title a {
    color: #26509c; }

.media-object__read-on {
  padding-top: 10px;
  margin-top: auto;
  text-align: right; }

.media-object:hover .media-object__title {
  color: #26509c; }
  .media-object:hover .media-object__title a {
    color: #26509c; }

.owl-carousel {
  overflow: hidden; }

.owl-theme .owl-item {
  overflow: hidden; }
  .owl-theme .owl-item img {
    width: auto; }

.owl-theme .view-image {
  line-height: 0;
  margin: 0; }
  .owl-theme .view-image.text-center img {
    display: inline-block; }
  .owl-theme .view-image a {
    display: block; }

.owl-theme .owl-nav > div {
  position: absolute;
  top: 50%;
  text-indent: -9000px;
  height: 70px;
  margin-top: -35px !important;
  width: 35px;
  padding: 0 !important;
  margin: 0;
  background: #fff !important;
  color: #888888 !important;
  animation-duration: 500ms;
  animation-fill-mode: both; }
  .owl-theme .owl-nav > div::before {
    font-family: "iconfont";
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 70px;
    vertical-align: middle;
    width: 35px;
    height: 70px;
    text-align: center;
    font-size: 18px; }
  .owl-theme .owl-nav > div:hover {
    color: #e1e1e1; }

.owl-theme .owl-nav .owl-prev {
  left: 0;
  border-radius: 0 70px 70px 0;
  animation-name: fadeOutLeft; }
  .owl-theme .owl-nav .owl-prev::before {
    content: ""; }

.owl-theme .owl-nav .owl-next {
  right: 0;
  border-radius: 70px 0 0 70px;
  animation-name: fadeOutRight; }
  .owl-theme .owl-nav .owl-next::before {
    content: ""; }

.owl-theme:hover .owl-nav .owl-prev {
  animation-name: fadeInLeft; }

.owl-theme:hover .owl-nav .owl-next {
  animation-name: fadeInRight; }

.owl-theme:hover .owl-dots {
  opacity: 1; }

.owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition-duration: 250ms;
  transition-property: opacity; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    background-color: #cccccc;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 3px;
    border: 5px solid #fff; }
    .owl-theme .owl-dots .owl-dot span {
      display: none; }
    .owl-theme .owl-dots .owl-dot:hover, .owl-theme .owl-dots .owl-dot.active {
      background-color: #26509c; }

.owlcarousel-settings-sidebar-single-slide {
  padding-bottom: 3rem; }

.field-name-field-content-paragraphs > .field-items > .field-item {
  margin-bottom: 1.5rem; }

.image-text > .row {
  display: flex; }
  .image-text > .row::before, .image-text > .row::after {
    display: none; }
  .image-text > .row > * {
    flex: 0 0 auto;
    width: 100%;
    height: auto !important; }
    @media only screen and (min-width: 768px) {
      .image-text > .row > * {
        flex-basis: 50%;
        max-width: 50%; } }

.image-text__image-col img {
  max-width: none;
  width: 100%; }

.image-text__text-col {
  align-self: center;
  display: flex;
  flex-direction: column; }
  .image-text__text-col :first-child {
    margin-top: 0; }
  .image-text__text-col :last-child {
    margin-bottom: 0; }
  .image-text__text-col .field-name-field-hyperlink {
    padding-top: 1rem;
    margin-top: auto; }

@media only screen and (min-width: 768px) {
  .image-text--image-position-last .row {
    flex-direction: row-reverse; } }

/* Price Views */
.price-label {
  padding: 16px 15px;
  position: relative;
  display: inline-block;
  background-color: #26509c;
  color: #fff;
  border-radius: 2px;
  margin-left: 8px;
  margin-right: 8px;
  line-height: 1.15em;
  min-height: 32px;
  text-align: left;
  transition-duration: 350ms;
  transition-property: transform;
  transform: translate(15px, -25px) rotate(-12deg); }
  .price-label::before {
    content: " ";
    position: absolute;
    border-radius: 50%;
    width: 16px;
    height: 76%;
    left: 0;
    margin-left: -8px;
    top: 12%;
    background-color: #26509c; }
  .price-label::after {
    content: " " !important;
    position: absolute;
    border-radius: 50%;
    width: 16px;
    height: 36px;
    right: 0;
    margin-right: -8px;
    top: 12%;
    background-color: #26509c;
    min-height: 76%;
    clear: none;
    height: auto !important;
    visibility: visible !important;
    display: block !important; }
  .price-label .field-label {
    font-size: 12px !important;
    font-weight: 300 !important;
    float: none !important;
    position: relative;
    line-height: 1em;
    white-space: nowrap;
    z-index: 5;
    margin-bottom: 2px;
    display: block !important;
    text-align: left !important; }
  .price-label .field-label-inline {
    flex-direction: column; }
  .price-label .field-items {
    font-weight: 600 !important;
    font-size: 18px;
    float: none !important;
    position: relative;
    z-index: 6;
    line-height: 1em;
    white-space: nowrap; }
    .price-label .field-items * {
      display: inline; }
  .price-label:hover {
    transform: translate(15px, -25px) rotate(-10deg) scale(1.3); }
  .price-label--with-uvp {
    padding-top: 6px;
    padding-bottom: 11px; }
    .price-label--with-uvp .field-name-field-angebot-uvp,
    .price-label--with-uvp .field-name-field-product-uvp {
      font-size: 12px; }
      .price-label--with-uvp .field-name-field-angebot-uvp .field-label,
      .price-label--with-uvp .field-name-field-product-uvp .field-label {
        display: inline !important; }
      .price-label--with-uvp .field-name-field-angebot-uvp .field-items,
      .price-label--with-uvp .field-name-field-product-uvp .field-items {
        font-size: 12px;
        text-decoration: line-through; }
  .price-label--on-request {
    max-width: 7em;
    text-align: center;
    font-weight: 600; }
    .price-label--on-request:hover, .price-label--on-request:focus {
      color: #fff; }

.percent-badge {
  background: #f96f00;
  color: white;
  height: 60px;
  width: 60px;
  padding: 8px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center; }
  .percent-badge .field-label {
    font-size: 12px;
    line-height: 12px;
    white-space: nowrap;
    margin-bottom: 2px;
    margin-top: 4px; }
  .percent-badge .field-items {
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;
    line-height: 1.35; }

.title-wrapper {
  overflow: hidden; }

.image-wrapper {
  margin-bottom: 1rem; }
  .image-wrapper .price-label {
    position: absolute;
    left: 5px;
    bottom: -60px; }
  .image-wrapper .percent-badge {
    position: absolute;
    right: -10px;
    top: -10px; }
  .image-wrapper--with-price {
    margin-bottom: 55px; }

.field-name-field-product-uvp-grundpreis,
.field-name-field-angebot-preis-grundpreis {
  font-size: 12px;
  margin-bottom: 1em; }
  .field-name-field-product-uvp-grundpreis *,
  .field-name-field-angebot-preis-grundpreis * {
    display: inline; }

.field-name-field-product-uvp-grundpreis
+ .field-name-field-angebot-preis-grundpreis {
  margin-top: -1em; }

.equal-height-desc-wrapper {
  overflow: hidden; }

.owl-item .thumbnail {
  border: 0 none !important;
  padding: 0 !important; }

.owl-item .image-wrapper {
  margin-top: 15px; }
  .owl-item .image-wrapper .percent-badge {
    right: 0; }

.cutline-top {
  border-top: 1px solid #cccccc;
  padding-top: 30px;
  margin-top: 30px; }

.cutline-bottom {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 30px;
  margin-bottom: 30px; }

.tagstyle,
.tagstyle ul,
.field-name-field-tags {
  margin: 0;
  padding: 0; }

.tagstyle li,
.tagstyle ul li,
.tagstyle .field-item,
.tagstyle--unlinked .field-item,
.tagstyle--unlinked li,
.field-name-field-tags .field-item {
  list-style: none;
  margin: 0 0.25em 0.25em 0;
  padding: 0;
  display: inline-block; }

.tagstyle li a,
.tagstyle ul li a,
.tagstyle .field-item a,
.tagstyle--unlinked .field-item,
.tagstyle--unlinked li,
.field-name-field-tags .field-item a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222;
  padding: 8px 10px;
  border-radius: 5px;
  line-height: 1em;
  font-size: 12px;
  line-height: 1.05em; }
  .tagstyle li a:hover,
  .tagstyle ul li a:hover,
  .tagstyle .field-item a:hover,
  .tagstyle--unlinked .field-item:hover,
  .tagstyle--unlinked li:hover,
  .field-name-field-tags .field-item a:hover {
    background-color: transparent;
    border-color: #bfbfbf;
    color: #26509c; }
  .tagstyle li a:active, .tagstyle li a:focus,
  .tagstyle ul li a:active,
  .tagstyle ul li a:focus,
  .tagstyle .field-item a:active,
  .tagstyle .field-item a:focus,
  .tagstyle--unlinked .field-item:active,
  .tagstyle--unlinked .field-item:focus,
  .tagstyle--unlinked li:active,
  .tagstyle--unlinked li:focus,
  .field-name-field-tags .field-item a:active,
  .field-name-field-tags .field-item a:focus {
    background-color: transparent;
    border-color: #9e9e9e;
    color: #222; }
  .tagstyle li a:hover,
  .tagstyle ul li a:hover,
  .tagstyle .field-item a:hover,
  .tagstyle--unlinked .field-item:hover,
  .tagstyle--unlinked li:hover,
  .field-name-field-tags .field-item a:hover {
    text-decoration: none; }

.tagstyle--unlinked > li {
  cursor: default; }

.subline,
.submitted {
  color: #a2a2a2;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0.5em;
  line-height: 1.15em; }
  .subline:not(h3),
  .submitted:not(h3) {
    font-size: 12px; }

.has-subline {
  margin-bottom: 0; }

.checklist {
  list-style: none outside none;
  padding-left: 5px; }
  .checklist li {
    margin-bottom: 0.75em;
    padding-left: 33px;
    position: relative; }
    .checklist li::before {
      border: 2px solid #cccccc;
      border-radius: 50%;
      color: #5cb85c;
      font-family: "iconfont";
      content: "";
      font-size: 18px;
      left: 0;
      line-height: 18px;
      padding: 2px;
      position: absolute;
      top: -2px; }

iframe {
  max-width: 100%; }

.embed-responsive,
.responsive-iframe-wrapper {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video,
  .responsive-iframe-wrapper-item,
  .responsive-iframe-wrapper iframe,
  .responsive-iframe-wrapper embed,
  .responsive-iframe-wrapper object,
  .responsive-iframe-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.embed-responsive-16by9,
.responsive-iframe-wrapper--video,
.responsive-iframe-wrapper--map {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

.pane--custom-directions-map .pane-title {
  margin-top: 0;
  font-size: 16px; }

.custom-directions-map {
  overflow: hidden;
  margin-bottom: 30px; }
  .custom-directions-map__form label {
    display: block; }

.popper {
  background: #26509c;
  color: #fff;
  padding: 15px;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.4); }
  .popper[x-placement^="top"] {
    margin-bottom: 5px; }
    .popper[x-placement^="top"] .popper__arrow {
      border-width: 5px 5px 0;
      border-color: #26509c rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0; }
  .popper[x-placement^="bottom"] {
    margin-top: 5px; }
    .popper[x-placement^="bottom"] .popper__arrow {
      border-width: 0 5px 5px;
      border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #26509c;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0; }
  .popper[x-placement^="right"] {
    margin-left: 5px; }
    .popper[x-placement^="right"] .popper__arrow {
      border-width: 5px 5px 5px 0;
      border-color: transparent #26509c transparent transparent;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0; }
  .popper[x-placement^="left"] {
    margin-right: 5px; }
    .popper[x-placement^="left"] .popper__arrow {
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent #26509c;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0; }
  .popper a {
    color: #fff; }
  .popper .popper__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px; }

.message,
div.messages {
  padding: 15px 15px 15px 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  position: relative;
  color: #222;
  background-color: #fff;
  border-color: #cccccc; }
  .message::before,
  div.messages::before {
    font-family: "iconfont";
    position: absolute;
    left: 15px;
    top: 14px;
    font-size: 25px;
    line-height: normal; }
  .message ul,
  div.messages ul {
    margin-top: 0;
    margin-bottom: 0; }
  .message a,
  div.messages a {
    color: #222; }
  .message::before,
  div.messages::before {
    content: ""; }

.message--info,
div.info {
  color: #1f7e9a;
  background-color: #c5e9f3;
  border-color: #5bc0de; }
  .message--info a,
  div.info a {
    color: #1f7e9a; }
  .message--info::before,
  div.info::before {
    content: ""; }

.message--success,
div.status {
  color: #2d672d;
  background-color: #b5dfb5;
  border-color: #5cb85c; }
  .message--success a,
  div.status a {
    color: #2d672d; }
  .message--success::before,
  div.status::before {
    content: ""; }

.message--warning,
div.warning {
  color: #b06d0f;
  background-color: #fae3c4;
  border-color: #f0ad4e; }
  .message--warning a,
  div.warning a {
    color: #b06d0f; }
  .message--warning::before,
  div.warning::before {
    content: ""; }

.message--error,
div.error {
  color: #8b211e;
  background-color: #f0b9b8;
  border-color: #d9534f; }
  .message--error a,
  div.error a {
    color: #8b211e; }
  .message--error::before,
  div.error::before {
    content: ""; }

tr.warning {
  color: #b06d0f; }

tr.error {
  color: #8b211e; }

input[type="text"].error,
.form-text.error,
textarea.error,
.error {
  border-color: #8b211e; }

.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: #1f7e9a;
  font-size: 75px;
  font-weight: 600;
  height: 0;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word; }

.link-map {
  position: relative; }

.link-map__image {
  line-height: 1; }

.link-map__image img {
  max-width: none;
  width: 100%; }

.link-map__overlay {
  background: #eee;
  color: currentColor;
  padding: 1rem 1rem 2rem;
  font-size: small;
  border-radius: 5px; }

.link-map__overlay > :last-child {
  margin-bottom: 0; }

@media print, screen and (min-width: 40em) {
  .link-map__overlay {
    position: absolute;
    left: 1rem;
    top: 1rem;
    padding: 0.75rem 1.25rem;
    max-width: calc(50% - 2.5rem);
    background: rgba(255, 255, 255, 0.85); } }

.link-map__directions-link-group {
  position: absolute;
  right: 0.5rem;
  top: 1rem;
  background: rgba(255, 255, 255, 0.85);
  color: #696969;
  padding: 0.25rem;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap; }

@media print, screen and (min-width: 40em) {
  .link-map__directions-link-group {
    max-width: calc(50% - 2.5rem); } }

.link-map__directions-link {
  flex: 1 1 auto;
  margin: 0.5rem;
  font-size: small;
  border: 1px solid #26509c;
  padding: 0.5rem 1rem;
  border-radius: 3px; }
  .link-map__directions-link:hover {
    background: #26509c;
    color: #fff; }
  .link-map__directions-link strong {
    display: block; }

.link-map__directions-link-label {
  white-space: nowrap; }

.link-map__copyright {
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
  color: #696969;
  font-size: small;
  line-height: 1.1;
  padding: 0.25em 0.75em; }
